import React from 'react';
import '../styles/Home.css'; // Make sure this import is present

function CurrentlyLearning() {
  const learningItems = [
    {
      topic: "GraphQL",
      description: "Exploring efficient data querying for APIs",
      progress: 60,
      resources: ["https://graphql.org/", "https://www.howtographql.com/"]
    },
    {
      topic: "Rust Programming",
      description: "Diving into systems programming and web assembly",
      progress: 30,
      resources: ["https://www.rust-lang.org/", "https://doc.rust-lang.org/book/"]
    },
    {
      topic: "Machine Learning with TensorFlow",
      description: "Building and training neural networks",
      progress: 45,
      resources: ["https://www.tensorflow.org/", "https://www.coursera.org/specializations/tensorflow-in-practice"]
    }
  ];

  return (
    <section className="currently-learning">
      <h2>What I'm Currently Learning</h2>
      <div className="learning-items">
        {learningItems.map((item, index) => (
          <div key={index} className="learning-item">
            <h3>{item.topic}</h3>
            <p>{item.description}</p>
            <div className="progress-bar">
              <div className="progress" style={{width: `${item.progress}%`}}></div>
            </div>
            <p>Progress: {item.progress}%</p>
            <div className="resources">
              <h4>Resources:</h4>
              <ul>
                {item.resources.map((resource, idx) => (
                  <li key={idx}>
                    <a href={resource} target="_blank" rel="noopener noreferrer">
                      Resource {idx + 1}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default CurrentlyLearning;