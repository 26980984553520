import React from 'react';
import '../styles/About.css';

function About() {
  return (
    <div className="about-section" style={{color: 'var(--text-color)'}}>
      <h1>About Me</h1>
      <div className="about-content">
        <img src={`${process.env.PUBLIC_URL}/pablo_headshot_mtzgroup.jpg`} alt="Pablo Unzueta" className="headshot" style={{float: 'left', marginRight: '20px', marginBottom: '10px'}} />
        <p>Hi! I'm Pablo Unzueta, a computational chemist with a passion for machine learning.
          I'm currently a Postdoc at Stanford University with an emphasis on simulating photochemistry⚡⚛️.
          I've started this website to share some of the skills and knowledge I've picked up along the way.</p>
        <p>
          When I'm not coding, you can find me playing basketball, fetch with my dog, or taking photos. I am also currently learning
          Japanese for my upcoming trip. 
        </p>
        <p>
          Feel free to explore my blog posts to see what I've been working on and 
          thinking about lately. If you'd like to collaborate or just chat don't hesitate to reach out!
          My email is located in my CV. 
        </p>
      </div>
    </div>
  );
}

export default About;