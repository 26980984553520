import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import BlogList from './components/BlogList';
import About from './components/About';
import Home from './components/Home';
import SearchResults from './components/SearchResults';
import BlogPost from './components/BlogPost';
import SearchIcon from './components/SearchIcon';
import './App.css';
import './styles/Blog.css';
import './styles/Footer.css';

function App() {
  return (
    <Router>
      <div className="site-container">
        <Header />
        <main className="site-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/blog/:id" element={<BlogPost />} />
          </Routes>
        </main>
        <SearchIcon />
        <Footer />
      </div>
    </Router>
  );
}

export default App;