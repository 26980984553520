import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllPosts } from '../utils/markdownUtils';

const BlogList = ({ darkMode }) => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const fetchedPosts = getAllPosts();
      console.log('Fetched posts:', fetchedPosts);
      setPosts(fetchedPosts);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to load posts. Please try again later.');
    }
  }, []);

  if (error) return <p>{error}</p>;

  return (
    <div className={`blog-list ${darkMode ? 'dark-mode' : ''}`}>
      {posts.length > 0 ? (
        posts.map((post, index) => (
          <div key={index} className="blog-card">
            <h2>{post.title}</h2>
            <p>{post.date}</p>
            <Link to={`/blog/${index}`}>Read more</Link>
          </div>
        ))
      ) : (
        <p>No posts found. Check the console for details.</p>
      )}
    </div>
  );
};

export default BlogList;