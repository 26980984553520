import React from 'react';

function Footer() {
  return (
    <footer className="bg-light text-dark py-3 mt-4">
      <div className="container text-center">
        <p className="mb-0">&copy; 2024 Pablo Unzueta. All rights reserved. Built with React.</p>
      </div>
    </footer>
  );
}

export default Footer;