import React from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
  return (
    <header>
      <nav className="main-nav">
        <ul>
          <li><NavLink to="/">Home</NavLink></li>
          <li><NavLink to="/about">About</NavLink></li>
          <li><NavLink to="/blog">Blog</NavLink></li>
          <li><a href="https://github.com/pablo-unzueta" target="_blank" rel="noopener noreferrer">GitHub</a></li>
          <li>
            <a 
              href={`${process.env.PUBLIC_URL}/cv_4.pdf`} 
              download="Pablo_Unzueta_CV.pdf"
            >
              Download CV
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;