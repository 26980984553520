import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SearchIcon.css';

function SearchIcon() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearchClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
      setSearchTerm('');
      setIsExpanded(false);
    }
  };

  return (
    <div className={`search-icon ${isExpanded ? 'expanded' : ''}`}>
      <button onClick={handleSearchClick}>🔍</button>
      {isExpanded && (
        <form onSubmit={handleSearchSubmit}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Blog..."
            autoFocus
          />
          <button type="submit">Search</button>
        </form>
      )}
    </div>
  );
}

export default SearchIcon;