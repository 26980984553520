import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getAllPosts } from '../utils/markdownUtils';

function SearchResults() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q');

  const allPosts = getAllPosts();
  const results = allPosts.filter(post => 
    post.title.toLowerCase().includes(query.toLowerCase()) ||
    post.content.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className="search-results">
      <h2>Search Results for "{query}"</h2>
      {results.length > 0 ? (
        results.map((post, index) => (
          <div key={index} className="search-result">
            <h3><Link to={`/blog/${index}`}>{post.title}</Link></h3>
            <p>{post.content.substring(0, 150)}...</p>
          </div>
        ))
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
}

export default SearchResults;