import React, { useState, useEffect } from 'react';
import CurrentlyLearning from './CurrentlyLearning.js';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home-page">
      <h1>Welcome to My Computational Chemistry and ML Journey</h1>
      <p>I'm Pablo Unzueta, a computational chemist with a passion for machine learning.
        Check out my <a href="/blog">blog</a> to see my thoughts on ML/comp chem, as well as some of my projects.
      </p>
      
      <CurrentlyLearning />
      
      {/* Other sections of your home page */}
    </div>
  );
}

export default Home;