import React from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getPostContent, getAllPosts } from '../utils/markdownUtils';

const BlogPost = ({ darkMode }) => {
  const { id } = useParams();
  const posts = getAllPosts();
  const post = posts[id];

  if (!post) return <div>Post not found</div>;

  const imagePath = process.env.PUBLIC_URL + '/images/';

  const customRenderers = {
    img: ({src, alt}) => (
      <img src={`${imagePath}${src.split('/').pop()}`} alt={alt} style={{maxWidth: '100%', height: 'auto'}} />
    )
  };

  return (
    <div className={`blog-post ${darkMode ? 'dark-mode' : ''}`}>
      <h1>{post.title}</h1>
      <p>{post.date}</p>
      <ReactMarkdown components={customRenderers}>{post.content}</ReactMarkdown>
    </div>
  );
}

export default BlogPost;