import matter from 'gray-matter';

// Use require.context to get all .md files from the posts directory
const postFiles = require.context('../posts', true, /\.md$/);

export function getPostContent(filename) {
  try {
    const key = `./${filename}`;
    if (!postFiles.keys().includes(key)) {
      throw new Error(`File not found: ${filename}`);
    }
    const postContent = postFiles(key);
    const { data, content } = matter(postContent);
    return {
      ...data,
      content
    };
  } catch (error) {
    console.error(`Error loading post ${filename}:`, error);
    return null;
  }
}

export function getAllPosts() {
  return postFiles.keys().map((key) => {
    const filename = key.replace(/^\.\//, '');
    return getPostContent(filename);
  }).filter(post => post !== null);
}